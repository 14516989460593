var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent, inject } from 'vue';
import LeftOutlined from '@ant-design/icons-vue/LeftOutlined';
import RightOutlined from '@ant-design/icons-vue/RightOutlined';
import DoubleLeftOutlined from '@ant-design/icons-vue/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons-vue/DoubleRightOutlined';
import { tuple } from '../_util/type';
import PropTypes, { withUndefined } from '../_util/vue-types';
import VcSelect from '../select';
import MiniSelect from './MiniSelect';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { getOptionProps } from '../_util/props-util';
import VcPagination from '../vc-pagination';
import enUS from '../vc-pagination/locale/en_US';
import { defaultConfigProvider } from '../config-provider';
import classNames from '../_util/classNames';
export const PaginationProps = () => ({
    total: PropTypes.number,
    defaultCurrent: PropTypes.number,
    disabled: PropTypes.looseBool,
    current: PropTypes.number,
    defaultPageSize: PropTypes.number,
    pageSize: PropTypes.number,
    hideOnSinglePage: PropTypes.looseBool,
    showSizeChanger: PropTypes.looseBool,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    buildOptionText: PropTypes.func,
    showSizeChange: PropTypes.func,
    showQuickJumper: withUndefined(PropTypes.oneOfType([PropTypes.looseBool, PropTypes.object])),
    showTotal: PropTypes.any,
    size: PropTypes.string,
    simple: PropTypes.looseBool,
    locale: PropTypes.object,
    prefixCls: PropTypes.string,
    selectPrefixCls: PropTypes.string,
    itemRender: PropTypes.func,
    role: PropTypes.string,
    showLessItems: PropTypes.looseBool,
});
export const PaginationConfig = () => (Object.assign(Object.assign({}, PaginationProps()), { position: PropTypes.oneOf(tuple('top', 'bottom', 'both')) }));
export default defineComponent({
    name: 'APagination',
    inheritAttrs: false,
    props: Object.assign({}, PaginationProps()),
    setup() {
        return {
            configProvider: inject('configProvider', defaultConfigProvider),
        };
    },
    methods: {
        getIconsProps(prefixCls) {
            const prevIcon = (<a class={`${prefixCls}-item-link`}>
          <LeftOutlined />
        </a>);
            const nextIcon = (<a class={`${prefixCls}-item-link`}>
          <RightOutlined />
        </a>);
            const jumpPrevIcon = (<a class={`${prefixCls}-item-link`}>
          
          <div class={`${prefixCls}-item-container`}>
            <DoubleLeftOutlined class={`${prefixCls}-item-link-icon`}/>
            <span class={`${prefixCls}-item-ellipsis`}>•••</span>
          </div>
        </a>);
            const jumpNextIcon = (<a class={`${prefixCls}-item-link`}>
          
          <div class={`${prefixCls}-item-container`}>
            <DoubleRightOutlined class={`${prefixCls}-item-link-icon`}/>
            <span class={`${prefixCls}-item-ellipsis`}>•••</span>
          </div>
        </a>);
            return {
                prevIcon,
                nextIcon,
                jumpPrevIcon,
                jumpNextIcon,
            };
        },
        renderPagination(contextLocale) {
            const _a = getOptionProps(this), { prefixCls: customizePrefixCls, selectPrefixCls: customizeSelectPrefixCls, buildOptionText, size, locale: customLocale } = _a, restProps = __rest(_a, ["prefixCls", "selectPrefixCls", "buildOptionText", "size", "locale"]);
            const getPrefixCls = this.configProvider.getPrefixCls;
            const prefixCls = getPrefixCls('pagination', customizePrefixCls);
            const selectPrefixCls = getPrefixCls('select', customizeSelectPrefixCls);
            const isSmall = size === 'small';
            const paginationProps = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({ prefixCls,
                selectPrefixCls }, restProps), this.getIconsProps(prefixCls)), { selectComponentClass: isSmall ? MiniSelect : VcSelect, locale: Object.assign(Object.assign({}, contextLocale), customLocale), buildOptionText: buildOptionText || this.$slots.buildOptionText }), this.$attrs), { class: classNames({ mini: isSmall }, this.$attrs.class), itemRender: this.itemRender || this.$slots.itemRender });
            return <VcPagination {...paginationProps}/>;
        },
    },
    render() {
        return (<LocaleReceiver componentName="Pagination" defaultLocale={enUS} children={this.renderPagination}/>);
    },
});
