var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { defineComponent } from 'vue';
import Checkbox from '../checkbox';
import Radio from '../radio';
import { SelectionBoxProps } from './interface';
import BaseMixin from '../_util/BaseMixin';
import { getOptionProps } from '../_util/props-util';
export default defineComponent({
    name: 'SelectionBox',
    mixins: [BaseMixin],
    inheritAttrs: false,
    props: SelectionBoxProps,
    setup() {
        return {
            unsubscribe: null,
        };
    },
    data() {
        return {
            checked: false,
        };
    },
    created() {
        this.checked = this.getCheckState(this.$props);
    },
    mounted() {
        this.subscribe();
    },
    beforeUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },
    methods: {
        getCheckState(props) {
            const { store, defaultSelection, rowIndex } = props;
            let checked = false;
            if (store.getState().selectionDirty) {
                checked = store.getState().selectedRowKeys.indexOf(rowIndex) >= 0;
            }
            else {
                checked =
                    store.getState().selectedRowKeys.indexOf(rowIndex) >= 0 ||
                        defaultSelection.indexOf(rowIndex) >= 0;
            }
            return checked;
        },
        subscribe() {
            const { store } = this;
            this.unsubscribe = store.subscribe(() => {
                const checked = this.getCheckState(this.$props);
                this.setState({ checked });
            });
        },
    },
    render() {
        const _a = Object.assign(Object.assign({}, getOptionProps(this)), this.$attrs), { type, rowIndex } = _a, rest = __rest(_a, ["type", "rowIndex"]);
        const { checked } = this;
        const checkboxProps = Object.assign({ checked }, rest);
        if (type === 'radio') {
            checkboxProps.value = rowIndex;
            return <Radio {...checkboxProps}/>;
        }
        return <Checkbox {...checkboxProps}/>;
    },
});
